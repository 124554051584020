.legendContainer {
  position: absolute;
  display: grid;
  justify-items: end;
  bottom: 15px;
  right: 15px;
  z-index: 3;
}

.legendButton {
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  border: none;
  border-radius: 2px;
  padding: 7px 14px;
  width: 100px;
  color: var(--waterBlue);
  font-size: 1.4rem;
  font-weight: bold;

  svg {
    fill: var(--waterBlue);
    transform: rotate(-90deg);
    height: 10px;
  }

  &[data-active='true'] {
    svg {
      transform: rotate(90deg);
    }
  }

  &[data-active='true'],
  &:hover {
    background: var(--waterBlue);
    color: var(--white);

    svg {
      fill: var(--white);
    }
  }
}

.legend {
  display: none;
  background-color: var(--white);
  padding: 10px 20px 20px 20px;
  min-width: 163px;

  &[data-active='true'] {
    display: block;
  }
}
