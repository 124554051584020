@use "sass:map";
@use "styles/variables.module.scss" as v;

.mapContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;

  @media #{map.get(v.$media, "desktopS")} {
    width: 100%;
  }
}

.radarButton {
  border: none;
  background: none;
  text-decoration: none;
  color: var(--waterBlue);
  position: relative;
  font-weight: 600;
  font-size: 1.8rem;
  padding: 8px 10px;

  @media #{map.get(v.$media, "mobileM")} {
    padding: 14px 20px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: var(--lightBlue);
    display: block;

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -4px;
      bottom: -8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: var(--lightBlue) transparent transparent transparent;
      z-index: 2;
    }
  }

  &[data-isactive='true'] {
    cursor: pointer;
    background-color: var(--lightBlue);
    display: block;

    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -4px;
      bottom: -8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: var(--lightBlue) transparent transparent transparent;
      z-index: 2;
    }
  }
}

.radarButtons {
  display: flex;
  justify-content: flex-start;

  .radarButton:nth-of-type(4) {
    display: none;

    @media #{map.get(v.$media, "tablet")} {
      display: block;
    }
  }
}

.imageContainer {
  width: 100%;
  display: grid;
  position: relative;
}

.radarImages {
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
  overflow: hidden;
}

.radarImagesWrapper {
  display: flex;
}

.radarImage {
  width: 100%;
  aspect-ratio: 1.11/1;
  min-height: 290px;
  position: relative;
  flex-shrink: 0;
  @media #{map.get(v.$media, "mobileM")} {
    min-height: 340px;
  }
  @media #{map.get(v.$media, "mobileL")} {
    min-height: 380px;
  }
}

.radarBackgroundImage {
  grid-row: 1;
  grid-column: 1;
  object-fit: fill;
  aspect-ratio: 1.11/1;
  min-height: 290px;
  position: relative;
  @media #{map.get(v.$media, "mobileM")} {
    min-height: 340px;
  }
  @media #{map.get(v.$media, "mobileL")} {
    min-height: 380px;
  }
}

.playoutControlContainer {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background: var(--lightBlue);
  position: relative;
}

.playButton {
  height: 50px;
  min-width: 50px;
  border-radius: 30px;
  cursor: pointer;
  background-color: var(--waterBlue);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;

  &[data-isplaying='false'] {
    svg {
      margin-left: 4px;
    }
  }
}

.sliderContainer {
  width: 100%;
  position: relative;
  margin-top: 4px;
}

.intervalMarks {
  display: flex;
  justify-content: space-between;
  margin: 0 22px;
  position: relative;
  top: 8px;
}

.intervalMark {
  background-color: var(--waterBlue);
  border-radius: 15px;
  width: 10px;
  height: 10px;

  &[data-isactive='true'] {
    background-color: var(--lightGreen);
  }
}

.intervalTimestamp {
  cursor: pointer;
  position: absolute;
  left: -12px;
  color: var(--waterBlue);
  padding-top: 14px;
}

.intervalMarkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playoutSlider {
  width: calc(100% - 48px);
  margin: 0 24px;
  -webkit-appearance: none;
  background: var(--waterBlue);
  outline: none;
  height: 6px;
  border-radius: 5px;
  position: absolute;
}

.rangeSlider {
  // this is important
  // the actual range slider should not be visible
  // so therefore its height is set to 0
  // the width though and everything else defined here
  // it's quite important
  height: 0;

  position: relative;
  width: calc(100% - 24px);
  margin: 3px 12px;
  -webkit-appearance: none;
  background: var(--waterBlue);
  outline: none;
  border-radius: 5px;
  position: absolute;

  &::-moz-range-thumb {
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--lightGreen);
    cursor: pointer;
    display: none;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--lightGreen);
    cursor: pointer;
  }
}

.zoomContainer {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.zoomButton {
  width: 50px;
  height: 50px;
  border: 4px solid var(--white);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    fill: var(--white);
    height: 30px;
  }
}

.timestamp {
	position: absolute;
	left: 15px;
	top: 15px;
	z-index: 2;
	font-size: 24px;
	font-weight: 600;
	color: var(--white);
	text-shadow: 1px 1px 0 var(--waterBlue);
}